/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect } from "react";
import { isMobileOnly } from "react-device-detect";
import CheckUserIdle from "Desktop/Common/CheckIdleTimer";
import { useDispatch, useSelector } from "react-redux";
import { checkSlug } from "store/actions/general.actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./App.css";
import {
  clearSlug,
  getDashBoardGames,
  logoutUser,
} from "store/actions/user.actions";
import { useHistory } from "react-router-dom";
import DesktopRoutes from "./Routes/desktopRoutes";
const dynamoClient = {};

function App(props) {
  const dispatch = useDispatch();
  const authed =
    localStorage.getItem("jwt_access_token") ||
    sessionStorage.getItem("jwt_access_token")
      ? true
      : false;
  const pageData = useSelector((state) => state.user.pageData);
  const domain = window.location.hostname.includes("localhost") || window.location.hostname.includes("new.nexs.io")
    ? "https://staging.nexs.io"
    : window.location.hostname;
  const path = window.location.pathname;
  const slug = localStorage.getItem("slug");
  const admin = localStorage.getItem("adminName");
  const newSlug = path?.split("/")[1];
  const history = useHistory();

  useEffect(() => {
    dispatch(getDashBoardGames(domain));
    if (slug || admin || path === "/") {
      if (slug && newSlug !== slug) {
        localStorage.removeItem("slug");
        dispatch(clearSlug());
        if (authed) dispatch(logoutUser());
      }
      if (admin && newSlug !== admin) {
        localStorage.removeItem("adminName");
        if (authed) dispatch(logoutUser());
      }
    }
  }, [dispatch, domain]);

  useEffect(() => {
    if (
      slug === null &&
      admin === null &&
      path !== "/" &&
      pageData?.whitelabel_admin_uuid
    ) {
      dispatch(
        checkSlug(path.substring(1), pageData?.whitelabel_admin_uuid, history)
      );
    }
  }, [dispatch, pageData]);
  return (
    <>
      {authed && <CheckUserIdle />}
      <Suspense
        fallback={
          <div className="main-loading-page">
            <CircularProgress size={50} style={{ color: "#D70101" }} />
          </div>
        }
      >
        <DesktopRoutes isMobileOnly={isMobileOnly} {...props} />
      </Suspense>
    </>
  );
}

export default App;
export { dynamoClient };
